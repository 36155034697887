export default {
    IconBtn: {
        icon: true,
        color: 'default',
        variant: 'text',
        density: 'comfortable',
        VIcon: { size: 22 },
    },
    VAlert: {
        density: 'comfortable',
        VBtn: { color: undefined },
    },
    VAvatar: { variant: 'flat' },
    VImg: { eager: true },
    VBadge: { color: 'primary' },
    VBtn: { color: 'primary' },
    VChip: { size: 'small' },
    VExpansionPanel: {
        expandIcon: 'mdi-chevron-right',
        collapseIcon: 'mdi-chevron-right',
    },
    VExpansionPanelTitle: {
        expandIcon: 'mdi-chevron-right',
        collapseIcon: 'mdi-chevron-right',
    },
    VList: {
        density: 'comfortable',
        VCheckboxBtn: { density: 'compact' },
    },
    VPagination: {
        activeColor: 'primary',
        density: 'comfortable',
        variant: 'tonal',
    },
    VTabs: {
        color: 'primary',
        density: 'comfortable',
        VSlideGroup: { showArrows: true },
    },
    VTooltip: { location: 'top' },
    VCheckboxBtn: { color: 'primary' },
    VCheckbox: {
        color: 'primary',
        density: 'comfortable',
        hideDetails: 'auto',
    },
    VRadioGroup: {
        color: 'primary',
        density: 'comfortable',
        hideDetails: 'auto',
    },
    VRadio: {
        density: 'comfortable',
        hideDetails: 'auto',
    },
    VSelect: {
        variant: 'outlined',
        color: 'primary',
        density: 'compact',
        hideDetails: 'auto',
        VChip: {
            color: 'primary',
            label: true,
        },
    },
    VRangeSlider: {
        color: 'primary',
        trackColor: 'rgb(var(--v-theme-on-surface),0.06)',
        trackSize: 6,
        thumbSize: 7,
        density: 'comfortable',
        thumbLabel: true,
        hideDetails: 'auto',
    },
    VRating: { color: 'warning' },
    VProgressCircular: { color: 'primary' },
    VProgressLinear: {
        height: 12,
        roundedBar: true,
        rounded: true,
        color: 'primary',
    },
    VSlider: {
        color: 'primary',
        trackColor: 'rgb(var(--v-theme-on-surface),0.06)',
        hideDetails: 'auto',
        thumbSize: 7,
        trackSize: 6,
    },
    VTextField: {
        variant: 'outlined',
        density: 'compact',
        color: 'primary',
        hideDetails: 'auto',
    },
    VAutocomplete: {
        variant: 'outlined',
        color: 'primary',
        density: 'compact',
        hideDetails: 'auto',
        menuProps: { contentClass: 'app-autocomplete__content v-autocomplete__content' },
        VChip: {
            color: 'primary',
            label: true,
        },
    },
    VCombobox: {
        variant: 'outlined',
        density: 'compact',
        color: 'primary',
        hideDetails: 'auto',
        VChip: {
            color: 'primary',
            label: true,
        },
    },
    VFileInput: {
        variant: 'outlined',
        density: 'compact',
        color: 'primary',
        hideDetails: 'auto',
    },
    VTextarea: {
        variant: 'outlined',
        density: 'compact',
        color: 'primary',
        hideDetails: 'auto',
    },
    VSwitch: {
        inset: true,
        color: 'primary',
        hideDetails: 'auto',
    },
    VTimeline: { lineThickness: 1 },

    VDataTable: {
        VDataTableFooter: {
            VBtn: {
                density: 'comfortable',
                color: 'default',
            },
        },
    },
};
