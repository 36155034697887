<script setup lang="ts">
import { useSnackbarStore } from '@/stores/snackbarStore';
import { type SnackbarType } from '@/types/SnackbarType';

const snackbarStore = useSnackbarStore();

function handleClick(snackbar: SnackbarType): void {
    if (snackbar.clickEvent) {
        snackbar.clickEvent();
    }
}
</script>

<template>
    <VSnackbar
        v-for="(snackbar, index) in snackbarStore.snackbars"
        :key="snackbar.id"
        :timeout="snackbar.timeout"
        :color="snackbar.color"
        :variant="snackbar.variant"
        :model-value="true"
        :style="`bottom: ${60 * index + 8}px`"
        :class="{'snackbar-link': snackbar.clickEvent !== undefined}"
        class="mb-5 mr-5 slide-leave-active"
        content-class="bg-white"
        transition="slide-fade"
        location="bottom right"
        @after-leave="snackbarStore.removeSnackbar(snackbar)"
        @click="handleClick(snackbar)"
    >
        <div class="d-flex align-center">
            <VIcon
                :icon="snackbar.icon"
                size="30"
                class="mr-3"
            />

            {{ snackbar.text }}
        </div>
    </VSnackbar>
</template>

<style lang="scss" scoped>
.slide-fade-enter-active {
    transition: all 1s ease;
}
.slide-leave-active {
    transition: all .7s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(50px);
    opacity: 0;
}
.snackbar-link{
    cursor: pointer;
}
</style>