import { PermissionEnum } from '@/enums/PermissionEnum';
import { CrmKeys } from '@/enums/RouteKeyEnums';
import { Crm } from '@/enums/RouteNameEnums';

export default [
    {
        title: 'Crm',
        icon: { icon: 'mdi-account-group' },
        children: [
            {
                title: Crm.CustomerList,
                to: Crm.CustomerList,
                action: PermissionEnum.CustomersRead,
                favoriteIcon: { icon: 'mdi-account-group' },
                key: CrmKeys.CustomerList,
            },
            {
                title: Crm.BlacklistList,
                to: Crm.BlacklistList,
                action: PermissionEnum.BlacklistRead,
                favoriteIcon: { icon: 'mdi-list-box' },
                key: CrmKeys.BlacklistList,
            },
        ],
        badgeClass: 'bg-global-primary',
    },
];
