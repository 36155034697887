import type { SnackbarMessageType } from '@/types/SnackbarMessageType';
import { SnackbarColor, SnackbarIcon } from '@/enums/SnackbarEnums';
import { useSnackbar } from '@/composables/useSnackbar';

export const handleSnackbarError = (errorMessage: string): SnackbarMessageType => {
    return {
        text: errorMessage,
        icon: SnackbarIcon.Error,
        color: SnackbarColor.Error,
    };
};

export const handleSnackbarSuccess = (successMessage: string): SnackbarMessageType => {
    return {
        text: successMessage,
        icon: SnackbarIcon.Success,
        color: SnackbarColor.Success,
    };
};

export const showSnackbar = (message: SnackbarMessageType): void => {
    useSnackbar().showSnackbar(message.text, message.color, message.icon);
};