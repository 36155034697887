import { PermissionEnum } from '@/enums/PermissionEnum';
import { AdminKeys } from '@/enums/RouteKeyEnums';
import { Admin } from '@/enums/RouteNameEnums';

export default [
    {
        title: 'Admin',
        icon: { icon: 'mdi-shield-crown' },
        children: [
            {
                title: Admin.Pulse,
                href: '/pulse',
                action: PermissionEnum.AdminPulse,
                favoriteIcon: { icon: 'mdi-chart-timeline-variant-shimmer' },
                key: AdminKeys.Pulse,
            },
        ],
    },
];
