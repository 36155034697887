import { PermissionEnum } from '@/enums/PermissionEnum';
import { VehiclesKeys } from '@/enums/RouteKeyEnums';
import { Vehicles } from '@/enums/RouteNameEnums';

export default [
    {
        title: 'Vehicles',
        icon: { icon: 'mdi-motorbike' },
        children: [
            {
                title: Vehicles.ProducerList,
                to: Vehicles.ProducerList,
                action: PermissionEnum.ProducersRead,
                favoriteIcon: { icon: 'mdi-factory' },
                key: VehiclesKeys.ProducerList,
            },
            {
                title: Vehicles.ModelList,
                to: Vehicles.ModelList,
                action: PermissionEnum.ModelsRead,
                favoriteIcon: { icon: 'mdi-motorbike' },
                key: VehiclesKeys.ModelList,
            },
            {
                title: Vehicles.SchemaList,
                to: Vehicles.SchemaList,
                action: PermissionEnum.SchemasRead,
                favoriteIcon: { icon: 'mdi-format-list-text' },
                key: VehiclesKeys.SchemaList,
            },
        ],
        badgeClass: 'bg-global-primary',
    },
];
