import { AuthKeys } from '@/enums/RouteKeyEnums';
import { Auth } from '@/enums/RouteNameEnums';

export default [
    {
        name: Auth.Login,
        path: '/login',
        component: () => import('@/views/authentication/LoginView.vue'),
        meta: {
            requiresAuth: false,
            layout: 'blank',
            key: AuthKeys.Login,
        },
    },
    {
        name: Auth.ForgotPassword,
        path: '/forgot-password',
        component: () => import('@/views/authentication/ForgotPasswordView.vue'),
        meta: {
            requiresAuth: false,
            layout: 'blank',
            key: AuthKeys.ForgotPassword,
        },
    },
    {
        name: Auth.ResetPassword,
        path: '/reset-password/:token',
        component: () => import('@/views/authentication/ResetPasswordView.vue'),
        props: (route: {
            params: { token: string; };
            query: { email: string; }; },
        ) => ({
            token: route.params.token,
            email: route.query.email,
        }),
        meta: {
            requiresAuth: false,
            layout: 'blank',
            key: AuthKeys.ResetPassword,
        },
    },
    {
        name: Auth.CreatePassword,
        path: '/create-password/:token',
        component: () => import('@/views/authentication/ResetPasswordView.vue'),
        props: (
            route: {
                params: { token: string; };
                query: { email: string; expires: string; };
            },
        ) => ({
            token: route.params.token,
            email: route.query.email,
            isCreate: true,
        }),
        meta: {
            requiresAuth: false,
            layout: 'blank',
            key: AuthKeys.CreatePassword,
        },
    },
];
