<script setup lang="ts">
import { RouterView } from 'vue-router';
import { hexToRgb } from '@/plugins/vuexy/@layouts/utils';
import ScrollToTop from '@/plugins/vuexy/@core/components/ScrollToTop.vue';
import { useTheme } from 'vuetify';
import Snackbar from '@/components/Snackbar.vue';
import { onBeforeMount } from 'vue';
import { useAxios } from './plugins/axios';
import ConfirmDialog from './components/dialogs/ConfirmDialog.vue';

onBeforeMount(async () => {
    await useAxios().get('/csrf-cookie');
});

const { global } = useTheme();
</script>

<template>
    <ConfirmDialog />
    <Snackbar />
    <VApp :style="`--v-global-theme-primary: ${hexToRgb(global.current.value.colors.primary)}`">
        <RouterView />
        <ScrollToTop />
    </VApp>
</template>