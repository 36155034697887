import { GeneralKeys } from '@/enums/RouteKeyEnums';
import { General } from '@/enums/RouteNameEnums';

export default [
    {
        name: General.Profile,
        path: '/profile',
        component: () => import('@/views/general/profile/ProfileView.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default',
            key: GeneralKeys.Profile,
        },
    },
    {
        name: General.Default,
        path: '/dashboard',
        component: () => import('@/views/app/GeneralDashboard.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default',
            key: GeneralKeys.Default,
        },
    },
];
