import { VThemeProvider } from 'vuetify/components/VThemeProvider';
import { computed, h } from 'vue';

export const useSkins = () => {

    const layoutAttrs = computed(() => ({
        verticalNavAttrs: {
            wrapper: h(VThemeProvider, { tag: 'aside' }),
            wrapperProps: {
                withBackground: true,
                theme: 'dark',
            },
        },
    }));

    return { layoutAttrs };
};
