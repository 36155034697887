import { useAxios } from '@/plugins/axios';
import { handleSnackbarError, handleSnackbarSuccess, showSnackbar } from './useSnackbarHelpers';
import i18n from '@/plugins/i18n';
import { RequestMethodEnum } from '@/enums/RequestMethodEnum';
import type { AxiosResponse } from 'axios';
import type { ResponseErrorType } from '@/types/response/ResponseErrorType';
import type { SnackbarMessageType } from '@/types/SnackbarMessageType';
import router from '@/plugins/router';

interface WithId {
    id: string | number;
}

export type HandleRequestParams = {
    method: RequestMethodEnum;
    endpoint: string;
    successMessageKey?: string;
    data?: any;
    onSuccess?: (response: AxiosResponse) => void;
    onError?: () => void;
    headers?: Record<string, string>;
    canShowSnackbar?: boolean;
};

export const handleRequest = async ({
    method,
    endpoint,
    successMessageKey,
    data,
    headers,
    onSuccess,
    onError,
    canShowSnackbar = true,
}: HandleRequestParams): Promise<AxiosResponse | ResponseErrorType> => {
    try {
        const response = await useAxios()[method](endpoint, data, { headers });
        if (onSuccess) {
            onSuccess(response);
        }

        if (canShowSnackbar && (successMessageKey || response.data.message)) {
            const messageKey: string = successMessageKey ? successMessageKey : ('be.message.' + response.data.message);
            const message: SnackbarMessageType = handleSnackbarSuccess(i18n.global.t(messageKey));
            showSnackbar(message);
        }

        return response;
    } catch (error: any) {
        console.error(error);
        if ((error.response as ResponseErrorType).data.message) {
            if (onError) {
                onError();
            }
            if (canShowSnackbar) {
                let errorMessage = (error.response as ResponseErrorType).data.message;
                if (errorMessage.endsWith('.')) {
                    errorMessage = errorMessage.slice(0, -1);
                }
                const message = handleSnackbarError(i18n.global.t('be.message.' + errorMessage));
                showSnackbar(message);
            }
        }

        return (error.response as ResponseErrorType);
    }
};

export const handleFormSuccess = <T extends WithId, L extends string, E extends string>(
    list: L,
    edit: E,
    closeDetail: boolean,
    item: T,
) => {
    if (closeDetail === true) {
        router.push({ name: list });
    } else if (item.id === undefined) {
        console.error('ID is not defined');
        router.push({ name: list });
    } else {
        router.push({ name: edit, params: { id: item.id.toString() } });
    }
};
