import { defineStore } from 'pinia';

export const useConfirmStore = defineStore('confirmStore', {
    state: () => ({
        open: false,
        title: '',
        text: '',
        cancelBtnText: 'general.cancel',
        confirmBtnText: 'general.confirm',
        resolve: null as ((result: boolean) => void) | null,
    }),
});