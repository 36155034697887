import axios from 'axios';
import { useAccountStore } from '@/stores/accountStore';
import router from '../router';
import { Auth, Error } from '@/enums/RouteNameEnums';

export const useAxios = (baseURL?: string) => {
    const a = axios.create({
        baseURL: baseURL ?? import.meta.env.VITE_API_BASE_URL,
        headers: { Authorization: `Bearer ${useAccountStore().getAuthorizationToken}` },
    });

    a.interceptors.response.use(
        response => response,
        async error => {
            if (error.response?.status === 401) {
                if (router.currentRoute.value.name !== Auth.ResetPassword && router.currentRoute.value.name !== Auth.CreatePassword) {
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('expires_in');
                    useAccountStore().account = null;
                    useAccountStore().profile = null;
                    useAccountStore().returnUrl = null;
                    router.push({ name: Auth.Login });
                }
            }

            if (error.response?.status === 403) {
                router.push({ name: Error.Error403 });
            }

            return Promise.reject(error);
        },
    );

    return a;
};
