import { defineStore } from 'pinia';
import system from '@/navigation/system';
import suppliers from '@/navigation/suppliers';
import crm from '@/navigation/crm';
import marketing from '@/navigation/marketing';
import vehicles from '@/navigation/vehicles';
import products from '@/navigation/products';
import admin from '@/navigation/admin';
import type { NavGroup, NavLink, VerticalNavItems } from '@/plugins/vuexy/@layouts/types';
import { handleRequest } from '@/helpers/useRequestHelpers';
import { RequestMethodEnum } from '@/enums/RequestMethodEnum';
import type { CustomResponseType } from '@/types/response/CustomAxiosResponseType';
import { useAccountStore } from '@/stores/accountStore';
import i18n from '@/plugins/i18n';

function findNavItemByKey(items: VerticalNavItems, key: string): NavLink | NavGroup | null {
    for (const item of items) {
        if ('key' in item && item.key === key) {
            return item;
        }
        if ('children' in item && item.children) {
            const found = findNavItemByKey(item.children, key);
            if (found) {
                return found;
            }
        }
    }

    return null;
}

type FavoriteModuleType = {
    id: number;
    module_name: string;
    user_id: number;
}

type StateType = {
    favoriteModules: FavoriteModuleType[];
    isLoading: boolean;
    allNavItems: (NavLink | NavGroup)[];
    favoriteModulesEndpoint: string;
}

export const useNavigationStore = defineStore('navigationStore', {
    state: (): StateType => ({
        favoriteModules: [],
        allNavItems: [
            ...admin,
            ...vehicles,
            ...crm,
            ...suppliers,
            ...marketing,
            ...products,
            ...system,
        ],
        isLoading: false,
        favoriteModulesEndpoint: '/favourite-modules',
    }),
    persist: { paths: ['favoriteModules'] },
    getters: {
        getAllNavItems(): VerticalNavItems {
            return this.allNavItems;
        },
        getFavoriteModulesKeys(): string[] {
            return this.favoriteModules.map((module: FavoriteModuleType)  => module.module_name);
        },
        getFavoriteModulesNavItems(state: StateType): (NavLink | NavGroup)[] {
            const data = this.getFavoriteModulesKeys
                .map((key: string) => {
                    const item = findNavItemByKey(state.allNavItems, key);
                    if (item) {
                        return {
                            ...JSON.parse(JSON.stringify(item)),
                            isFavoriteModule: true,
                        };
                    }

                    return null;
                })
                .filter((item): item is NavLink | NavGroup => item !== null);

            return data.sort((a, b) => {
                const aTitleTranslated = i18n.global.t('nav.' + a.title);
                const bTitleTranslated = i18n.global.t('nav.' + b.title);

                return aTitleTranslated.localeCompare(bTitleTranslated, i18n.global.locale.value, { sensitivity: 'base' });
            });
        },
        getNavItems(state: StateType): VerticalNavItems {
            const navHeader = [
                { heading: 'general.navigation' },
            ];

            const favoriteHeader = [
                { heading: 'general.favoriteModules' },
            ];

            const items: VerticalNavItems = [];
            if (state.favoriteModules.length > 0) {
                items.push(...favoriteHeader, ...this.getFavoriteModulesNavItems, ...navHeader);
            }
            items.push(...state.allNavItems);

            return items;
        },
    },
    actions: {
        async fetchFavoriteModules(): Promise<void> {
            this.isLoading = true;
            await handleRequest({
                method: RequestMethodEnum.Get,
                endpoint: `${this.favoriteModulesEndpoint}`,
                onSuccess: (response: CustomResponseType<FavoriteModuleType[]>) => {
                    this.favoriteModules = response.data.data;
                },
            });
            this.isLoading = false;
        },
        async addFavoriteModule(module: string): Promise<void> {
            const accountStore = useAccountStore();
            this.isLoading = true;
            await handleRequest({
                method: RequestMethodEnum.Post,
                endpoint: `${this.favoriteModulesEndpoint}`,
                data: {
                    user_id: accountStore.getProfile?.id,
                    module_name: module,
                },
                successMessageKey: 'message.favoriteModuleAdded',
                onSuccess: async (response: CustomResponseType<FavoriteModuleType>) => {
                    this.favoriteModules.push(response.data.data);
                },
            });
            this.isLoading = false;
        },
        async removeFavoriteModule(module: string): Promise<void> {
            const moduleId = this.getFavoriteModuleId(module);
            this.isLoading = true;
            await handleRequest({
                method: RequestMethodEnum.Delete,
                endpoint: `${this.favoriteModulesEndpoint}/${moduleId}`,
                successMessageKey: 'message.favoriteModuleRemoved',
                onSuccess: () => {
                    const index = this.favoriteModules.findIndex(mod => mod.id === moduleId);
                    if (index > -1) {
                        this.favoriteModules.splice(index, 1);
                    }
                },
            });
            this.isLoading = false;
        },
        getFavoriteModuleId(module: string): number | null {
            const found = this.favoriteModules.find((item: FavoriteModuleType) => item.module_name === module);
            if (found) {
                return found.id;
            }

            return null;
        },
    },
});
