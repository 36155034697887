import { type SnackbarType } from '@/types/SnackbarType';
import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';

type State = {
    snackbars: SnackbarType[];
};

export const useSnackbarStore = defineStore('snackbar', {
    state: (): State => ({ snackbars: [] as SnackbarType[] }),
    actions: {
        addSnackbar(snackbar: SnackbarType): void {
            snackbar.id = uuidv4();
            this.snackbars.push(snackbar);
        },

        removeSnackbar(snackbar: SnackbarType): void {
            const index = this.snackbars.indexOf(snackbar);
            if (index > -1) {
                this.snackbars.splice(index, 1);
            }
        },
    },
});
