import { createI18n } from 'vue-i18n';
import en from '@/plugins/i18n/translations/en.json';
import cs from '@/plugins/i18n/translations/cs.json';
import { DataGridI18n } from '@webstacz/data-grid-vue3';

const messages = {
    en: { ...en, ...DataGridI18n.global.messages.value.en },
    cs: { ...cs, ...DataGridI18n.global.messages.value.cs },
};

const storedLocale = localStorage.getItem('locale');

const i18n = createI18n({
    legacy: false,
    locale: storedLocale ? storedLocale : 'cs',
    fallbackLocale: 'cs',
    messages: messages,
});

export default i18n;