import { PermissionEnum } from '@/enums/PermissionEnum';
import { ProductsKeys } from '@/enums/RouteKeyEnums';
import { Products } from '@/enums/RouteNameEnums';

export default [
    {
        title: 'Products',
        icon: { icon: 'mdi-package-variant' },
        children: [
            {
                title: Products.ProductList,
                to: Products.ProductList,
                action: PermissionEnum.ProductsRead,
                favoriteIcon: { icon: 'mdi-package-variant' },
                key: ProductsKeys.ProductList,
            },
            {
                title: Products.ParametersList,
                to: Products.ParametersList,
                action: PermissionEnum.ParametersRead,
                favoriteIcon: { icon: 'mdi-numeric' },
                key: ProductsKeys.ParametersList,
            },
            {
                title: Products.CategoryList,
                to: Products.CategoryList,
                action: PermissionEnum.CategoriesRead,
                favoriteIcon: { icon: 'mdi-file-tree' },
                key: ProductsKeys.CategoryList,
            },
        ],
    },
];
