/**
 * Enum of all used permissions
 */
export enum PermissionEnum {

    // Marketing CMS
    CmsRead = 'cms.read',
    CmsWrite = 'cms.write',
    CmsDelete = 'cms.delete',

    // Marketing Vouchers
    VouchersRead = 'vouchers.read',
    VouchersWrite = 'vouchers.write',
    VouchersDelete = 'vouchers.delete',

    // System Users
    UsersRead = 'users.read',
    UsersWrite = 'users.write',
    UsersDelete = 'users.delete',

    // System Languages
    LanguagesRead = 'languages.read',
    LanguagesWrite = 'languages.write',
    LanguagesDelete = 'languages.delete',

    // System Currencies
    CurrenciesRead = 'currencies.read',
    CurrenciesWrite = 'currencies.write',
    CurrenciesDelete = 'currencies.delete',

    // System Taxes
    TaxesRead = 'taxes.read',
    TaxesWrite = 'taxes.write',
    TaxesDelete = 'taxes.delete',

    // System Countries
    CountriesRead = 'countries.read',
    CountriesWrite = 'countries.write',
    CountriesDelete = 'countries.delete',

    // System Domains
    DomainsRead = 'domains.read',
    DomainsWrite = 'domains.write',
    DomainsDelete = 'domains.delete',

    // System PaymentMethods
    PaymentMethodsRead = 'payment_methods.read',
    PaymentMethodsWrite = 'payment_methods.write',
    PaymentMethodsDelete = 'payment_methods.delete',

    // System Companies
    CompaniesRead = 'companies.read',
    CompaniesWrite = 'companies.write',
    CompaniesDelete = 'companies.delete',

    // CRM
    CrmRead = 'crm.read',
    CrmWrite = 'crm.write',
    CrmDelete = 'crm.delete',
    CustomersRead = 'customers.read',
    CustomersWrite = 'customers.write',
    CustomersDelete = 'customers.delete',
    BlacklistRead = 'blacklist.read',
    BlacklistWrite = 'blacklist.write',
    BlacklistDelete = 'blacklist.delete',

    // User Absences
    UserAbsencesRead = 'user_absences.read',
    UserAbsencesWrite = 'user_absences.write',
    UserAbsencesDelete = 'user_absences.delete',

    // Carriers
    CarriersRead = 'carriers.read',
    CarriersWrite = 'carriers.write',
    CarriersDelete = 'carriers.delete',

    // Suppliers
    SuppliersRead = 'suppliers.read',
    SuppliersWrite = 'suppliers.write',
    SuppliersDelete = 'suppliers.delete',

    // Activity log
    ActivityLogsRead = 'activity_logs.read',

    // filemanager
    FilemanagerRead = 'filemanager.read',
    FilemanagerWrite = 'filemanager.write',
    FilemanagerDelete = 'filemanager.delete',

    // Products
    ProductsRead = 'products.read',
    ProductsWrite = 'products.write',
    ProductsDelete = 'products.delete',

    // Products Paramaters
    ParametersRead = 'parameters.read',
    ParametersWrite = 'parameters.write',
    ParametersDelete = 'parameters.delete',

    // Products Paramaters
    CategoriesRead = 'categories.read',
    CategoriesWrite = 'categories.write',
    CategoriesDelete = 'categories.delete',

    // Vehicles
    VehiclesRead = 'vehicles.read',
    VehiclesWrite = 'vehicles.write',
    VehiclesDelete = 'vehicles.delete',

    // Vehicles Models
    ModelsRead = 'models.read',
    ModelsWrite = 'models.write',
    ModelsDelete = 'models.delete',

    // Vehicles Producers
    ProducersRead = 'producers.read',
    ProducersWrite = 'producers.write',
    ProducersDelete = 'producers.delete',

    // Vehicles Schemas
    SchemasRead = 'schemas.read',
    SchemasWrite = 'schemas.write',
    SchemasDelete = 'schemas.delete',

    // Admin
    AdminPulse = 'admin.pulse',
}
