import { SnackbarColor, SnackbarIcon } from '@/enums/SnackbarEnums';
import { useSnackbarStore } from '@/stores/snackbarStore';
import { type SnackbarType, type SnackbarVariant } from '@/types/SnackbarType';

export function useSnackbar() {
    const snackbarStore = useSnackbarStore();

    function showSnackbar(
        text: string,
        color: SnackbarColor = SnackbarColor.Info,
        icon: SnackbarIcon = SnackbarIcon.Info,
        variant: SnackbarVariant = 'flat',
        timeout: number = 5000,
    ): void {
        const snackbar: SnackbarType = {
            text,
            color,
            icon,
            variant,
            timeout,
        };

        snackbarStore.addSnackbar(snackbar);
    }

    return { showSnackbar };
}