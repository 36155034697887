<script setup lang="ts">
import { useConfirmStore } from '@/stores/confirmStore';
import { useI18n } from 'vue-i18n';

const confirmStore = useConfirmStore();
const { t } = useI18n();

const confirm = (): void => {
    if (confirmStore.resolve) {
        confirmStore.resolve(true);
        confirmStore.open = false;
    }
};
const cancel = (): void => {
    if (confirmStore.resolve) {
        confirmStore.resolve(false);
        confirmStore.open = false;
    }
};
</script>

<template>
    <VDialog
        v-model="confirmStore.open"
        max-width="400"
    >
        <VCard>
            <VCardTitle class="pa-4 bg-primary">
                <span class="title">{{ confirmStore.title }}</span>
            </VCardTitle>

            <VCardText>
                <p class="font-weight-bold">
                    {{ confirmStore.text }}
                </p>
            </VCardText>

            <VCardActions class="pa-4">
                <VSpacer />

                <VBtn
                    color="secondary"
                    variant="outlined"
                    @click="cancel"
                >
                    {{ t(confirmStore.cancelBtnText) }}
                </VBtn>

                <VBtn
                    color="error"
                    variant="flat"
                    @click="confirm"
                >
                    {{ t(confirmStore.confirmBtnText) }}
                </VBtn>
            </VCardActions>
        </VCard>
    </VDialog>
</template>