import { PermissionEnum } from '@/enums/PermissionEnum';
import { ProductsKeys } from '@/enums/RouteKeyEnums';
import { Products } from '@/enums/RouteNameEnums';

export default [
    {
        name: Products.ProductList,
        path: '/products',
        component: () => import('@/views/products/products/views/ProductList.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default',
            canAddToFavorite: true,
            permissions: { read: PermissionEnum.ProductsRead },
            key: ProductsKeys.ProductList,
        },
    },
    {
        name: Products.ProductCreate,
        path: '/products/create',
        component: () => import('@/views/products/products/views/ProductDetail.vue'),
        props: () => ({ id: 'create' }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            create: true,
            navActiveLink: Products.ProductList,
            permissions: { write: PermissionEnum.ProductsWrite },
            key: ProductsKeys.ProductCreate,
        },
    },
    {
        name: Products.ProductEdit,
        path: '/products/:id',
        component: () => import('@/views/products/products/views/ProductDetail.vue'),
        props: (route: { params: { id: number; } }) => ({ id: route.params.id }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            navActiveLink: Products.ProductList,
            permissions: { write: PermissionEnum.ProductsWrite },
            key: ProductsKeys.ProductEdit,
        },
    },
    {
        name: Products.ParametersList,
        path: '/products/parameters',
        component: () => import('@/views/products/parameters/views/ParameterList.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default',
            canAddToFavorite: true,
            permissions: { read: PermissionEnum.ParametersRead },
            key: ProductsKeys.ParametersList,
        },
    },
    {
        name: Products.ParameterCreate,
        path: '/products/parameters/create',
        component: () => import('@/views/products/parameters/views/ParameterDetail.vue'),
        props: () => ({ id: 'create' }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            create: true,
            navActiveLink: Products.ParametersList,
            permissions: { write: PermissionEnum.ParametersWrite },
            key: ProductsKeys.ParameterCreate,
        },
    },
    {
        name: Products.ParameterEdit,
        path: '/products/parameters/:id',
        component: () => import('@/views/products/parameters/views/ParameterDetail.vue'),
        props: (route: { params: { id: number; } }) => ({ id: route.params.id }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            navActiveLink: Products.ParametersList,
            permissions: { write: PermissionEnum.ParametersWrite },
            key: ProductsKeys.ParameterEdit,
        },
    },
    {
        name: Products.CategoryList,
        path: '/products/categories',
        component: () => import('@/views/products/categories/views/CategoryList.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default',
            canAddToFavorite: true,
            permissions: { read: PermissionEnum.CategoriesRead },
            key: ProductsKeys.CategoryList,
        },
    },
    {
        name: Products.CategoryCreate,
        path: '/products/categories/create',
        component: () => import('@/views/products/categories/views/CategoryDetail.vue'),
        props: () => ({ id: 'create' }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            create: true,
            navActiveLink: Products.CategoryList,
            permissions: { write: PermissionEnum.CategoriesWrite },
            key: ProductsKeys.CategoryCreate,
        },
    },
    {
        name: Products.CategoryEdit,
        path: '/products/categories/:id',
        component: () => import('@/views/products/categories/views/CategoryDetail.vue'),
        props: (route: { params: { id: number; } }) => ({ id: route.params.id }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            navActiveLink: Products.CategoryList,
            permissions: { write: PermissionEnum.CategoriesWrite },
            key: ProductsKeys.CategoryEdit,
        },
    },
];
