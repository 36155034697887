import { PermissionEnum } from '@/enums/PermissionEnum';
import { SuppliersKeys } from '@/enums/RouteKeyEnums';
import { Suppliers } from '@/enums/RouteNameEnums';

export default [
    {
        title: Suppliers.SupplierList,
        to: Suppliers.SupplierList,
        icon: { icon: 'mdi-truck' },
        action: PermissionEnum.SuppliersRead,
        key: SuppliersKeys.SupplierList,
    },
];
