import { defineStore } from 'pinia';
import { useAxios } from '@/plugins/axios';
import moment from 'moment';
import type { UserType } from '@/types/UserType';
import { handleRequest } from '@/helpers/useRequestHelpers';
import { Auth } from '@/enums/RouteNameEnums';
import router from '@/plugins/router';
import { handleSnackbarError, handleSnackbarSuccess, showSnackbar } from '@/helpers/useSnackbarHelpers';
import { RequestMethodEnum } from '@/enums/RequestMethodEnum';
import i18n from '@/plugins/i18n';
import type { AxiosResponse } from 'axios';
import type { CustomResponseType } from '@/types/response/CustomAxiosResponseType';
import type { ResponseErrorType } from '@/types/response/ResponseErrorType';
import { useNavigationStore } from '@/stores/navigationStore';

type Account = {
    access_token: string;
    token_type: string;
    expires_in: number;
}

type State = {
    profile: UserType | null;
    account: Account | null;
    returnUrl: string | null;
    isLoading: boolean;
}

export const useAccountStore = defineStore('accountStore', {
    state: (): State => ({
        profile: null,
        account: null,
        returnUrl: null,
        isLoading: false,
    }),
    getters: {
        getAuthorizationToken(): string {
            if (this.account) {
                return `${this.account.access_token}`;
            }

            const token = localStorage.getItem('access_token');

            return token ? token : '';
        },
        getProfile(): UserType | null {
            return this.profile;
        },
        hasPermission: state => (permissions: string | string[]): boolean => {
            if (state.profile?.admin) {
                return true;
            }
            if (state.profile) {
                return typeof permissions === 'string'
                    ? state.profile?.permissions.includes(permissions)
                    : permissions.every(state.profile.permissions.includes);
            }

            return false;
        },
    },
    actions: {
        async fetch(canShowSnackbar: boolean = true): Promise<AxiosResponse | null> {
            this.isLoading = true;
            const response = await handleRequest({
                method: RequestMethodEnum.Get,
                endpoint: '/user/profile',
                onSuccess: (response: CustomResponseType<UserType>) => {
                    this.profile = response.data.data;
                },
                canShowSnackbar,
            });
            this.isLoading = false;
            if (response?.data.data) {
                return response.data.data;
            }

            return null;
        },
        async login(email: string, password: string): Promise<void> {
            this.isLoading = true;

            try {
                const response = await useAxios().post('/auth/login', { email, password });

                this.account = response.data;
                const expiresAt = moment().add(response.data.expires_in, 'seconds').unix();
                localStorage.setItem('access_token', response.data.access_token);
                localStorage.setItem('expires_in', expiresAt.toString());
                await this.fetch();
                if (this.profile && this.profile.locale) {
                    i18n.global.locale.value = this.profile.locale;
                    localStorage.setItem('locale', this.profile.locale);
                }
                await useNavigationStore().fetchFavoriteModules();
            } catch (error: any) {
                console.error(error);
                const message = handleSnackbarError(i18n.global.t('be.message.' + error.response.data.message));
                showSnackbar(message);
            } finally {
                this.isLoading = false;
            }
        },
        async getCsfrToken(): Promise<void> {
            await useAxios().get('/csrf-cookie');
        },
        isTokenValid(): boolean {
            const token = localStorage.getItem('access_token');
            const expiresAt = localStorage.getItem('expires_in');

            if (!token) {
                return false;
            }

            return !(expiresAt && moment().unix() > Number(expiresAt));
        },
        async loadUser(): Promise<boolean> {
            try {
                if (this.profile) {
                    return true;
                }
                if (this.isTokenValid()) {
                    await this.fetch();

                    return true;
                }

                return false;
            } catch (error) {
                return false;
            }
        },
        async logout(redirectToLogin: boolean = true): Promise<void> {
            this.isLoading = true;
            await handleRequest({
                method: RequestMethodEnum.Post,
                endpoint: '/auth/logout',
                successMessageKey: '',
                data: null,
                onSuccess: () => {
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('expires_in');
                    this.account = null;
                    this.profile = null;
                    this.returnUrl = null;
                    if (redirectToLogin) {
                        router.push({ name: Auth.Login });
                    }
                },
            });
            this.isLoading = false;
        },
        async sendPasswordResetEmail(email: string): Promise<void> {
            this.isLoading = true;
            await handleRequest({
                method: RequestMethodEnum.Post,
                endpoint: '/auth/login/forgot-password',
                successMessageKey: 'be.message.passwords.sent',
                data: { email },
            });
            this.isLoading = false;
        },
        async resetPassword(
            email: string,
            password: string,
            password_confirmation: string,
            token: string,
            isCreate: boolean = false,
        ): Promise<void> {
            this.isLoading = true;
            await handleRequest({
                method: RequestMethodEnum.Post,
                endpoint: isCreate ? '/auth/login/create-password' : '/auth/login/reset-password',
                successMessageKey: isCreate ? 'message.createPasswordSuccess' : 'message.resetPasswordSuccess',
                data: { email, password, password_confirmation, token },
                onSuccess: () => {
                    router.push({ name: Auth.Login });
                },
            });
            this.isLoading = false;
        },
        async updateProfile({ item, showSuccessMessage = true }: {item: UserType, showSuccessMessage?: boolean}): Promise<AxiosResponse | ResponseErrorType> {
            this.isLoading = true;
            const result: AxiosResponse | ResponseErrorType = await handleRequest({
                method: RequestMethodEnum.Put,
                endpoint: '/user/profile',
                successMessageKey: showSuccessMessage ? 'message.profileUpdated' : '',
                data: item,
                onSuccess: async (response: CustomResponseType<UserType>) => {
                    if (this.getProfile === null) {
                        await this.fetch();
                    }
                    if (this.getProfile?.id === item.id && this.getProfile?.locale !== item.locale) {
                        this.setLocale(item.locale);
                    }
                    this.profile = response.data.data;
                },
            });
            this.isLoading = false;

            return result;
        },
        setLocale(locale: 'cs' | 'en'): void {
            i18n.global.locale.value = locale;
            this.profile!.locale = locale;
            localStorage.setItem('locale', locale);
        },
    },
});
