export enum SnackbarColor {
    Success = 'success',
    Error = 'error',
    Info = 'info',
}

export enum SnackbarIcon {
    Success = 'mdi-check-circle',
    Error = 'mdi-alert',
    Info = 'mdi-information',
}