import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router';
import AuthRoutes from '@/plugins/router/AuthRoutes';
import GeneralRoutes from '@/plugins/router/GeneralRoutes';
import { setupLayouts } from 'virtual:generated-layouts';
import { Auth, General, Error } from '@/enums/RouteNameEnums';
import { useAccountStore } from '@/stores/accountStore';
import SystemRoutes from '@/plugins/router/SystemRoutes';
import CrmRoutes from '@/plugins/router/CrmRoutes';
import SupplierRoutes from '@/plugins/router/SupplierRoutes';
import MarketingRoutes from '@/plugins/router/MarketingRoutes';
import ErrorRoutes from '@/plugins/router/ErrorRoutes';
import VehiclesRoutes from '@/plugins/router/VehiclesRoutes';
import ProductRoutes from '@/plugins/router/ProductRoutes';
import { can, isValueOfEnum, setTabTitle } from '@/helpers/useDefault';
import i18n from '@/plugins/i18n';

function recursiveLayouts(route: RouteRecordRaw): RouteRecordRaw {
    if (route.children) {
        for (let i = 0; i < route.children.length; i++) {
            route.children[i] = recursiveLayouts(route.children[i]);
        }

        return route;
    }

    return setupLayouts([route])[0];
}

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        ...AuthRoutes,
        ...GeneralRoutes,
        ...SystemRoutes,
        ...CrmRoutes,
        ...VehiclesRoutes,
        ...SupplierRoutes,
        ...MarketingRoutes,
        ...ProductRoutes,
        ...ErrorRoutes,
        {
            path: '/',
            name: General.Home,
            redirect: { name: General.Default },
        },
    ].map(route => recursiveLayouts(route)),
});

router.beforeEach(async (to, from, next) => {
    const publicPageNames = [Auth.Login, Auth.ForgotPassword, Auth.ResetPassword];
    const authRequired = !publicPageNames.includes(to.name as Auth);
    const accountStore = useAccountStore();

    setTabTitle({
        title: to.meta?.title?.toString() ?? i18n.global.t('nav.' + to.name?.toString()),
        suffix: 'PARTS DEPOT',
    });

    if (to.matched.some(record => record.meta.requiresAuth)) {
        const isAuthenticated = await accountStore.loadUser();

        if (authRequired && !isAuthenticated) {
            accountStore.returnUrl = to.fullPath;

            return next({ name: Auth.Login });
        }

        if (isValueOfEnum(General, to.name!.toString())) {
            next();

            return;
        }

        const hasPermissions = to.meta && to.meta.permissions && Object.keys(to.meta.permissions).length > 0;
        const canVisit = Object.values(to.meta?.permissions ?? {}).some(x => can(x));

        if (!hasPermissions || !canVisit) {
            next({ name: Error.Error403 });

            return;
        }
    } else {
        const isAuthenticated = await accountStore.loadUser();

        if (isAuthenticated && publicPageNames.includes(to.name as Auth) && to.name !== Auth.ResetPassword) {
            return next({ name: General.Default });
        }
    }

    next();
});

export default router;
