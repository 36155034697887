import { PermissionEnum } from '@/enums/PermissionEnum';
import { MarketingKeys } from '@/enums/RouteKeyEnums';
import { Marketing } from '@/enums/RouteNameEnums';

export default [
    {
        title: 'Marketing',
        icon: { icon: 'mdi-bullhorn' },
        children: [
            {
                title: Marketing.VoucherList,
                to: Marketing.VoucherList,
                action: PermissionEnum.VouchersRead,
                favoriteIcon: { icon: 'mdi-ticket-percent' },
                key: MarketingKeys.VoucherList,
            },
        ],
        badgeClass: 'bg-global-primary',
    },
];
