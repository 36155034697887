import { PermissionEnum } from '@/enums/PermissionEnum';
import { SuppliersKeys } from '@/enums/RouteKeyEnums';
import { Suppliers } from '@/enums/RouteNameEnums';

export default [
    {
        name: Suppliers.SupplierList,
        path: '/suppliers',
        component: () => import('@/views/suppliers/views/SupplierList.vue'),
        meta: {
            requiresAuth: true,
            canAddToFavorite: true,
            layout: 'default',
            permissions: { read: PermissionEnum.SuppliersRead },
            key: SuppliersKeys.SupplierList,
        },
    },
    {
        name: Suppliers.SupplierCreate,
        path: '/suppliers/create',
        component: () => import('@/views/suppliers/views/SupplierDetail.vue'),
        props: () => ({ id: 'create' }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            create: true,
            navActiveLink: Suppliers.SupplierList,
            breadcrumb: [{ title: Suppliers.SupplierList }],
            permissions: { write: PermissionEnum.SuppliersWrite },
            key: SuppliersKeys.SupplierCreate,
        },
    },
    {
        name: Suppliers.SupplierDetail,
        path: '/suppliers/:id',
        component: () => import('@/views/suppliers/views/SupplierDetail.vue'),
        props: (route: { params: { id: number; } }) => ({ id: route.params.id }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            navActiveLink: Suppliers.SupplierList,
            breadcrumb: [{ title: Suppliers.SupplierList }],
            permissions: { read: PermissionEnum.SuppliersRead },
            key: SuppliersKeys.SupplierDetail,
        },
    },
];
