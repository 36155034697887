import { createApp } from 'vue';
import { createPinia } from 'pinia';
import vuetify from '@/plugins/vuetify';
import '@mdi/font/css/materialdesignicons.css';
import App from './App.vue';
import router from '@/plugins/router';
import '@/plugins/vuexy/@core/scss/template/index.scss';
import '@styles/styles.scss';
import i18n from '@/plugins/i18n';
import { delayedInputDirective } from '@webstacz/data-grid-vue3';
import 'vue3-perfect-scrollbar/style.css';
import { PerfectScrollbarPlugin } from 'vue3-perfect-scrollbar';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import * as Sentry from '@sentry/vue';
import { env } from '@/envProvider';

const app = createApp(App);
const pinia = createPinia();

if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration(),
        ],
        environment: env('ENVIRONMENT', 'local'),
        release: import.meta.env.VITE_VERSION,
        tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE || 1.0,
        replaysSessionSampleRate: import.meta.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE || 0.1,
        replaysOnErrorSampleRate: import.meta.env.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE || 1.0,
    });
}

pinia.use(piniaPluginPersistedstate);

app.directive('delayed-input', delayedInputDirective);
app.use(vuetify);
app.use(pinia);
app.use(PerfectScrollbarPlugin);
app.use(router);
app.use(i18n);

app.mount('#app');
