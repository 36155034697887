import { PermissionEnum } from '@/enums/PermissionEnum';
import { VehiclesKeys } from '@/enums/RouteKeyEnums';
import { Vehicles } from '@/enums/RouteNameEnums';

export default [
    {
        name: Vehicles.ProducerList,
        path: '/vehicles/producers',
        component: () => import('@/views/vehicles/producers/views/ProducerList.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default',
            canAddToFavorite: true,
            permissions: { read: PermissionEnum.ProducersRead },
            key: VehiclesKeys.ProducerList,
        },
    },
    {
        name: Vehicles.ProducerCreate,
        path: '/vehicles/producers/create',
        component: () => import('@/views/vehicles/producers/views/ProducerDetail.vue'),
        props: () => ({ id: 'create' }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            create: true,
            navActiveLink: Vehicles.ProducerList,
            permissions: { write: PermissionEnum.ProducersWrite },
            key: VehiclesKeys.ProducerCreate,
        },
    },
    {
        name: Vehicles.ProducerEdit,
        path: '/vehicles/producers/:id',
        component: () => import('@/views/vehicles/producers/views/ProducerDetail.vue'),
        props: (route: { params: { id: number; } }) => ({ id: route.params.id }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            navActiveLink: Vehicles.ProducerList,
            permissions: { read: PermissionEnum.ProducersRead },
            key: VehiclesKeys.ProducerEdit,
        },
    },
    {
        name: Vehicles.ModelList,
        path: '/vehicles/models',
        component: () => import('@/views/vehicles/models/views/ModelList.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default',
            canAddToFavorite: true,
            permissions: { read: PermissionEnum.ModelsRead },
            key: VehiclesKeys.ModelList,
        },
    },
    {
        name: Vehicles.ModelCreate,
        path: '/vehicles/models/create',
        component: () => import('@/views/vehicles/models/views/ModelDetail.vue'),
        props: () => ({ id: 'create' }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            create: true,
            navActiveLink: Vehicles.ModelList,
            permissions: { write: PermissionEnum.ModelsWrite },
            key: VehiclesKeys.ModelCreate,
        },
    },
    {
        name: Vehicles.ModelEdit,
        path: '/vehicles/models/:id',
        component: () => import('@/views/vehicles/models/views/ModelDetail.vue'),
        props: (route: { params: { id: number; } }) => ({ id: route.params.id }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            navActiveLink: Vehicles.ModelList,
            permissions: { read: PermissionEnum.ModelsRead },
            key: VehiclesKeys.ModelEdit,
        },
    },
    {
        name: Vehicles.SchemaList,
        path: '/vehicles/schemas',
        component: () => import('@/views/vehicles/schemas/views/SchemaList.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default',
            canAddToFavorite: true,
            permissions: { read: PermissionEnum.SchemasRead },
            key: VehiclesKeys.SchemaList,
        },
    },
    {
        name: Vehicles.SchemaCreate,
        path: '/vehicles/schemas/create',
        component: () => import('@/views/vehicles/schemas/views/SchemaDetail.vue'),
        props: () => ({ id: 'create' }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            create: true,
            navActiveLink: Vehicles.SchemaList,
            permissions: { write: PermissionEnum.SchemasWrite },
            key: VehiclesKeys.SchemaCreate,
        },
    },
    {
        name: Vehicles.SchemaEdit,
        path: '/vehicles/schemas/:id',
        component: () => import('@/views/vehicles/schemas/views/SchemaDetail.vue'),
        props: (route: { params: { id: number; } }) => ({ id: route.params.id }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            navActiveLink: Vehicles.SchemaList,
            permissions: { read: PermissionEnum.SchemasRead },
            key: VehiclesKeys.SchemaEdit,
        },
    },
];
