import { PermissionEnum } from '@/enums/PermissionEnum';
import { MarketingKeys } from '@/enums/RouteKeyEnums';
import { Marketing } from '@/enums/RouteNameEnums';

export default [
    {
        name: Marketing.VoucherList,
        path: '/marketing/vouchers',
        component: () => import('@/views/marketing/vouchers/views/VoucherList.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default',
            canAddToFavorite: true,
            permissions: { read: PermissionEnum.VouchersRead },
            key: MarketingKeys.VoucherList,
        },
    },
    {
        name: Marketing.VoucherDetail,
        path: '/marketing/vouchers/:id',
        component: () => import('@/views/marketing/vouchers/views/VoucherDetail.vue'),
        props: (route: { params: { id: number; } }) => ({ id: route.params.id }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            navActiveLink: Marketing.VoucherList,
            permissions: { read: PermissionEnum.VouchersRead },
            key: MarketingKeys.VoucherDetail,
        },
    },
    {
        name: Marketing.VoucherCreate,
        path: '/marketing/vouchers/create',
        component: () => import('@/views/marketing/vouchers/views/VoucherDetail.vue'),
        props: () => ({ id: 'create' }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            create: true,
            navActiveLink: Marketing.VoucherList,
            permissions: { write: PermissionEnum.VouchersWrite },
            key: MarketingKeys.VoucherCreate,
        },
    },
];
