import { PermissionEnum } from '@/enums/PermissionEnum';
import { CrmKeys } from '@/enums/RouteKeyEnums';
import { Crm } from '@/enums/RouteNameEnums';

export default [
    {
        name: Crm.CustomerList,
        path: '/crm/customers',
        component: () => import('@/views/crm/customers/views/CustomerList.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default',
            canAddToFavorite: true,
            permissions: { read: PermissionEnum.CustomersRead },
            key: CrmKeys.CustomerList,
        },
    },
    {
        name: Crm.CustomerEdit,
        path: '/crm/customers/:id',
        component: () => import('@/views/crm/customers/views/CustomerDetail.vue'),
        props: (route: { params: { id: number; } }) => ({ id: route.params.id }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            navActiveLink: Crm.CustomerList,
            permissions: { read: PermissionEnum.CustomersRead },
            key: CrmKeys.CustomerEdit,
        },
    },
    {
        name: Crm.BlacklistList,
        path: '/crm/blacklist',
        component: () => import('@/views/crm/blacklist/views/BlacklistList.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default',
            permissions: { read: PermissionEnum.BlacklistRead },
            key: CrmKeys.BlacklistList,
            canAddToFavorite: true,
        },
    },
    {
        name: Crm.BlacklistCreate,
        path: '/crm/blacklist/create', component: () => import('@/views/crm/blacklist/views/BlacklistDetail.vue'),
        props: () => ({ id: 'create' }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            create: true,
            navActiveLink: Crm.BlacklistList,
            permissions: { write: PermissionEnum.BlacklistWrite },
            key: CrmKeys.BlacklistCreate,
        },
    },
    {
        name: Crm.BlacklistEdit,
        path: '/crm/blacklist/:id', component: () => import('@/views/crm/blacklist/views/BlacklistDetail.vue'),
        props: (route: { params: { id: number; } }) => ({ id: route.params.id }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            navActiveLink: Crm.BlacklistList,
            permissions: { read: PermissionEnum.BlacklistRead },
            key: CrmKeys.BlacklistEdit,
        },
    },
];
