import { ErrorKeys } from '@/enums/RouteKeyEnums';
import { Error } from '@/enums/RouteNameEnums';

export default [
    {
        path: '/not-authorized',
        name: Error.Error403,
        meta: {
            requiresAuth: false,
            layout: 'blank',
            key: ErrorKeys.Error403,
        },
        component: () => import('@/plugins/vuexy/403.vue'),
    },
    {
        path: '/:pathMatch(.*)*',
        name: Error.Error404,
        meta: {
            requiresAuth: false,
            layout: 'blank',
            key: ErrorKeys.Error404,
        },
        component: () => import('@/plugins/vuexy/404.vue'),
    },
];
