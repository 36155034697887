import { createVuetify } from 'vuetify';
import { VBtn } from 'vuetify/components/VBtn';
import defaults from './defaults';
import { vuexyDark } from './theme';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import '@/plugins/vuexy/@core/scss/template/libs/vuetify/index.scss';
import 'vuetify/styles';
import DayJsAdapter from '@date-io/dayjs';
import enDate from 'dayjs/locale/en';
import csDate from 'dayjs/locale/cs';
import i18n from '@/plugins/i18n';
import { cs, en } from 'vuetify/locale';

export default createVuetify({
    components,
    directives,
    aliases: { IconBtn: VBtn },
    defaults,
    icons: { defaultSet: 'mdi' },
    theme: {
        defaultTheme: 'vuexyDark',
        themes: { vuexyDark },
    },
    locale: { locale: i18n.global.locale.value, fallback: 'en', messages: { en, cs } },
    date: {
        adapter: DayJsAdapter,
        locale: {
            en: enDate,
            cs: csDate,
        },
    },
});