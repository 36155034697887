<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
import { useSkins } from '@/plugins/vuexy/@core/composable/useSkins';

const DefaultLayoutWithVerticalNav = defineAsyncComponent(() => import('./components/DefaultLayoutWithVerticalNav.vue'));
const { layoutAttrs } = useSkins();
</script>

<template>
    <Component
        v-bind="layoutAttrs"
        :is="DefaultLayoutWithVerticalNav"
    />
</template>

<style lang="scss">
// As we are using `layouts` plugin we need its styles to be imported
@use "@/plugins/vuexy/@layouts/styles/default-layout";
</style>
